import req from '../utils/request.js'
const path = '/api/faq'
const imsPath = '/api/pms'

/* 获取模块列表 */
export function getModuleList(params) {
  return req.get(`${path}/module/query`, params)
}

/* 新增模块 */
export function addModule(params) {
  return req.post(`${path}/module/add`, params)
}

/* 获取目录列表 */
export function getDirectoriesList(moduleId, parentId) {
  if (parentId) {
    // 这个接口获取不了
    return req.get(`${path}/directory/query/${moduleId}/${parentId}`)
  } else {
    return req.get(`${path}/directory/query/${moduleId}`)
  }
}

/* 查询文章列表 */
export function queryArticleList(directoryId, params) {
  return req.get(`${path}/article/query/${directoryId}`, params)
}

/* 查询文章信息 */
export function queryArticleInfo(articleId) {
  return req.get(`${path}/article/get/${articleId}`)
}

/* 新增文章 */
export function addArticle(params) {
  return req.post(`${path}/article/add`, params)
}

/* 更新文章 */
export function updateArticle(articleId, params) {
  return req.put(`${path}/article/update/${articleId}`, params)
}

/* 删除文章 */
export function deleteArticle(articleId) {
  return req.delete(`${path}/article/delete/${articleId}`)
}

/* 更新文章元数据 */
export function updateArticleDertail(articleId, params) {
  return req.put(`${path}/article/meta/update/${articleId}`, params)
}

/* 搜索文章 */
export function searchArticle(params) {
  return req.get(`${path}/article/search`, params)
}

/* 上传 */
export function upload(moduleId, parentId) {
  return req.get(`${path}/directory/query/${moduleId}/${parentId}`)
}

/**
 * 新增类目
 */
export function addDirectory(params) {
  return req.post(`${path}/directory/add`, params)
}

/**
 * 编辑类目
 */
export function editDirectory(directoryId, params) {
  return req.put(`${path}/directory/update/${directoryId}`, params)
}

/**
 * 删除类目
 */
export function deleteDirectory(directoryId) {
  return req.delete(`${path}/directory/delete/${directoryId}`)
}

/**
 *
 * 文章设置or取消为常见问题
 * @param {文章id} articleId
 * @param {1:设置为常见问题，0:取消常见问题} faqState
 * @returns
 */
export function setArticleFaqState(articleId, faqState) {
  return req.put(`${path}/article/updateIsFaq/${articleId}?isFaq=${faqState}`)
}

/**
 * 常见问题
 */
export function getFaqList() {
  return req.get(`${path}/homepage/getFaqList`)
}

/**
 * 常见问题排序
 */
export function updateFaqSorts(params) {
  return req.post(`${path}/article/updateFaqSorts `, params)
}

/**
 * 文章排序
 */
export function updateSorts(params) {
  return req.post(`${path}/article/updateSorts `, params)
}

/**
 * 目录排序
 */
export function updateDirectorySorts(params) {
  return req.post(`${path}/directory/updateSorts `, params)
}

/**
 * 最近更新
 */
export function getLastUpdateList() {
  return req.get(`${path}/homepage/getLastUpdates`)
}

/* 电子房价牌登录 */
export function priceSignLogin(params) {
  return req.get(`${imsPath}/cbh/hotelInfo/priceSignLogin?hotelCode=${params}`)
}
