<template>
  <div v-show="moduleList.length > 0" class="home">
    <!--  header -->
    <div id="header" v-show="!inSearchPage">
      <img class="logo" src="../assets/logo.png" alt="" @click="showHomePage" />
      <a-divider type="vertical" class="divider" />
      <span class="title">帮助中心</span>
      <div class="search" @click="search">
        <svg
          t="1730705156773"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="7067"
        >
          <path
            d="M451.784 902.94a451.156 451.156 0 0 1-319.57-132.34 451.156 451.156 0 1 1 638.387-638.386A451.156 451.156 0 0 1 451.784 902.94z m0-827.12a375.964 375.964 0 0 0-265.43 642.898 375.964 375.964 0 1 0 532.364-532.365A375.964 375.964 0 0 0 451.784 75.82z"
            fill="#999999"
            p-id="7068"
          ></path>
          <path
            d="M281.848 660.067a37.596 37.596 0 0 1-26.317-11.279 278.965 278.965 0 0 1 0-393.258 37.596 37.596 0 0 1 53.387 53.387 203.02 203.02 0 0 0 0 287.236 37.596 37.596 0 0 1-26.318 63.914zM986.404 1024a37.596 37.596 0 0 1-26.317-11.279l-241.37-241.369a37.596 37.596 0 1 1 53.388-53.386l241.368 241.368a37.596 37.596 0 0 1-26.317 63.914z"
            fill="#999999"
            p-id="7069"
          ></path>
        </svg>
        <span class="text">搜索</span>
      </div>
      <a-icon type="menu" class="menu" @click="showMenu" />
    </div>
    <div :class="inSearchPage ? 'home-content_search' : 'home-content'">
      <a-layout-content class="root-content">
        <router-view class="root-content"></router-view>
      </a-layout-content>
    </div>
    <a-drawer
      title=""
      placement="right"
      :closable="false"
      :visible="menuVisible"
      @close="hideMenu"
    >
      <a-menu
        v-if="showModule"
        mode="inline"
        :style="{ height: '100%', borderRight: 0 }"
      >
        <a-menu-item v-for="item in showModule.directoryList" :key="item.id">
          {{ item.name }}
        </a-menu-item>
      </a-menu>
      <a-menu v-else mode="inline" :style="{ height: '100%', borderRight: 0 }">
        <a-sub-menu v-for="module in moduleList" :key="module.id">
          <span slot="title">{{ module.name }}</span>
          <a-menu-item
            v-for="item in module.directoryList"
            :key="item.id"
            @click="showDirectoryInfo(item)"
          >
            {{ item.name }}
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
    </a-drawer>
    <!-- <a-layout-footer id="footer"> © {{ nowYear }} 嘿住科技 </a-layout-footer> -->
  </div>
</template>

<script>
import { getDirectoriesList, getModuleList, searchArticle } from '@/api'
export default {
  name: 'helpMobile',
  data() {
    return {
      menuVisible: false,
      //  :default-selected-keys="['1']"
      //   :default-open-keys="['sub1']"
      nowYear: new Date().getFullYear(),
      breadcrumb: {},
      inSearchPage: false,
      collapsed: false,
      moduleId: '',
      moduleList: [], // 模块列表
      showModule: null,
      directoryList: [], // 目录列表
      isUpdate: false,
      uplateArticleTitle: '',
      selectedKeys: [],
      openKeys: [],
      screenWidth: ''
    }
  },
  created() {},
  mounted() {
    this.getModuleList()
    this.$forceUpdate()
  },
  methods: {
    /**
     * 获取模块列表
     */
    async getModuleList() {
      const data = await getModuleList()
      if (data.code !== 'FAQ-20000') {
        return
      }
      const moduleList = data.data.modules
      this.moduleList = moduleList
      this.$store.state.moduleList = moduleList
      moduleList.forEach((module) => {
        this.getModuleDirectories(module)
      })
    },

    /**
     * 获取模块目录
     */
    async getModuleDirectories(module) {
      const data = await getDirectoriesList(module.id)
      if (!data || data.code !== 'FAQ-20000') {
        return
      }
      const originalList = data.data.directories
      const list = []
      // 只取一级目录
      originalList.forEach((item) => {
        if (!item.parentId) {
          list.push(item)
        }
      })
      module.directoryList = list
    },

    /**
     * 显示menu
     */
    showMenu() {
      this.menuVisible = true
    },

    showModuleMenu(module) {
      this.showModule = module
      this.menuVisible = true
    },

    /**
     * 显示menu
     */
    hideMenu() {
      this.menuVisible = false
      this.showModule = null
    },

    /**
     * 目录详情
     */
    showDirectoryInfo(directory) {
      // // 如果当前在list页面，那么不跳转直接传递数据
      // if (this.$route.path.indexOf('/mobile/list') >= 0) {
      //   this.$router.replace({
      //     path: '/article/mobile/list',
      //     force: true,
      //     query: {
      //       moduleId: directory.moduleId,
      //       directoryId: directory.id
      //     }
      //   })
      //   return
      // }

      this.$router.push({
        path: '/article/mobile/list',
        query: {
          moduleId: directory.moduleId,
          directoryId: directory.id
        }
      })
    },

    /**
     * 显示首页
     */
    showHomePage() {
      const inHomePage = this.$route.path.indexOf('/home') >= 0
      if (!inHomePage) {
        this.$router.push({
          path: '/article/mobile/home'
        })
      }
    },

    /**
     * 进入搜索页面
     */
    search() {
      this.$router.push({
        path: '/article/mobile/search'
      })
    }
  },

  computed: {
    /**
     * 是否在搜索页面
     */
    isInSearchPage() {
      return this.$route.path.indexOf('/search') >= 0
    }
  },

  watch: {
    isInSearchPage: {
      deep: true,
      immediate: true,
      handler: function (n) {
        this.inSearchPage = n
      }
    }
  }
}
</script>
<style lang="less" scoped>
.home {
  text-align: left;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5;
}
#header {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  z-index: 9;
  width: 100%;
  height: 100px;
  background: #ffffff;

  .logo {
    margin-left: 25px;
    height: 55px;
    width: 192px;
  }

  .divider {
    height: 33px;
    margin: 0 20px;
  }

  .title {
    font-size: 25px;
    color: #222a54;
  }

  .search {
    height: 36px;
    padding: 0 14px;
    border-radius: 50px 50px 50px 50px;
    border: 1px solid #999999;
    position: relative;
    align-items: center;
    display: flex;
    margin-left: 30px;
    flex-direction: row;
    .icon {
      width: 16px;
      height: 16px;
    }
    .text {
      font-weight: 350;
      font-size: 24px;
      margin-left: 5px;
      color: #999999;
    }
  }
  .menu {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 0;
    right: 25px;
  }
}

.home-content {
  padding-top: 100px;
  height: 100%;
}

.home-content_search {
  height: 100%;
}

.root-content {
  height: 100%;
  width: 100%;
}

#footer {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  width: 100%;
  height: 120px;
  text-align: center;
}

.hepler-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;

  .helper-text {
    font-size: 18px;
    font-family: 'PingFang SC-中等', 'PingFang SC';
    font-weight: 500;
    color: #399cd0;
  }

  .helper-nav {
    display: flex;

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
      padding: 0;
      margin: 0;
      font-weight: 500;

      li {
        display: flex;
        align-items: center;
        border-radius: 20px;
        height: 40px;
        cursor: pointer;
        padding: 0 15px;
        margin-left: 35px;
        font-size: 18px;
        font-weight: normal;
        color: #399cd0;
      }

      li:hover {
        background-color: #f6f7f8;
        // color: #284ad1;
      }
    }
  }
}
.active {
  background-color: #f6f7f8;
  color: #284ad1 !important;
}

/deep/.ant-menu-submenu-title {
  text-align: left;
}
.ant-menu-item {
  text-align: left;
  // color: #222a54;
}
.ant-breadcrumb {
  text-align: left;
}

.menu-title {
  font-size: 16px;
  // color: #222a54;
  height: 50px;
  font-weight: 900;
  line-height: 50px;
  display: inline-block;
}
.noAside {
  padding: 0 240px;
}

//  搜索
.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}
.certain-category-search-wrapper
  .certain-category-search.ant-select-auto-complete
  .ant-input-affix-wrapper
  .ant-input-suffix {
  right: 12px;
}
.certain-category-search-wrapper .certain-search-item-count {
  position: absolute;
  color: #999;
  right: 16px;
}
.certain-category-search-wrapper
  .certain-category-search.ant-select-focused
  .certain-category-icon {
  color: #108ee9;
}
.certain-category-search-wrapper .certain-category-icon {
  color: #6e6e6e;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 16px;
}
</style>
