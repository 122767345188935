import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/rem'

import 'ant-design-vue/dist/antd.css'
import '@wangeditor/editor/dist/css/style.css'
import {
  AutoComplete,
  Breadcrumb,
  Button,
  Input,
  FormModel,
  Form,
  Icon,
  Select,
  Popconfirm,
  DatePicker,
  Radio,
  Switch,
  Table,
  Col,
  Divider,
  Layout,
  Popover,
  Menu,
  ConfigProvider,
  Modal,
  Pagination,
  Upload,
  Tag,
  Tree,
  Tooltip,
  Checkbox,
  Slider,
  Carousel,
  InputNumber,
  message,
  Spin,
  TimePicker,
  Progress,
  Alert,
  TreeSelect,
  Empty,
  Anchor,
  Tabs,
  Drawer
} from 'ant-design-vue'
import moment from 'moment'
import 'moment/locale/zh-cn'
import './utils/components.js'
import './style/public.less'

import * as filters from './utils/filter.js'
// 全局过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

Vue.prototype.$message = message
Vue.prototype.$confirm = Modal.confirm
Vue.config.productionTip = false

moment.locale('zh-cn')
Vue.use(Anchor)
Vue.use(AutoComplete)
Vue.use(Empty)
Vue.use(Breadcrumb)
Vue.use(Button)
Vue.use(Input)
Vue.use(Form)
Vue.use(Select)
Vue.use(Table)
Vue.use(Icon)
Vue.use(Popconfirm)
Vue.use(Col)
Vue.use(Radio)
Vue.use(DatePicker)
Vue.use(Switch)
Vue.use(Divider)
Vue.use(FormModel)
Vue.use(Layout)
Vue.use(Popover)
Vue.use(ConfigProvider)
Vue.use(Modal)
Vue.use(Pagination)
Vue.use(Upload)
Vue.use(Tag)
Vue.use(Tree)
Vue.use(Menu)
Vue.use(Checkbox)
Vue.use(Slider)
Vue.use(Carousel)
Vue.use(InputNumber)
Vue.use(Tooltip)
Vue.use(Spin)
Vue.use(TimePicker)
Vue.use(Progress)
Vue.use(Alert)
Vue.use(TreeSelect)
Vue.use(Tabs)
Vue.use(Drawer)

window.this = new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
