var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showPage)?_c('div',[_c('a-layout',{attrs:{"id":"root-container"}},[_c('a-layout-header',[_c('div',{staticClass:"logo pointer",on:{"click":_vm.showHomePage}},[_c('img',{staticClass:"image",attrs:{"src":require("../assets/logo.png"),"alt":""}}),_c('a-divider',{staticClass:"divider",attrs:{"type":"vertical"}}),_c('span',{staticClass:"text"},[_vm._v("帮助中心")])],1),_c('div',{staticClass:"nav"},[_c('ul',_vm._l((_vm.moduleList),function(item,index){return _c('li',{key:index,attrs:{"value":item.id}},[_c('span',{class:_vm.checkedModule.id === item.id ? 'active' : '',on:{"click":function($event){return _vm.chooseModule(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")]),(index === 0)?_c('a-divider',{attrs:{"type":"vertical"}}):_vm._e()],1)}),0)])]),(!_vm.hasToken)?_c('div',{staticClass:"container-token",staticStyle:{"margin-top":"64px","height":"calc(100vh - 64px)"}},[_c('a-input',{staticClass:"input",attrs:{"placeholder":"请输入正确的token"}})],1):_c('a-layout',{class:_vm.showSider ? '' : 'noAside'},[(_vm.showSider)?_c('a-layout-sider',{attrs:{"width":"300"}},[_c('div',{staticClass:"add-directory",on:{"click":_vm.showAddDirectoryModal}},[_c('span',{staticClass:"text"},[_vm._v("添加目录")]),_c('a-icon',{attrs:{"type":"plus-circle"}})],1),_c('a-divider',{staticClass:"add-directory-divider",attrs:{"type":"horizontal"}}),_c('a-menu',{attrs:{"mode":"inline","openKeys":_vm.openKeys,"selectedKeys":_vm.selectedKeys},on:{"openChange":_vm.openFirstLevelDirectory}},[_vm._l((_vm.directoryList),function(item,groupIndex){return [(item.children.length === 0)?_c('a-menu-item',{key:item.id,attrs:{"id":item.id},on:{"mouseenter":function($event){return _vm.mouseEnterDirectory(item.id)},"mouseleave":function($event){return _vm.mouseLeaveDirectory()},"click":function($event){return _vm.openSecondaryDirectory(item.name, item)}}},[_c('a-icon',{style:('visibility:' +
                    (groupIndex !== 0 && _vm.mouseFocusOnDirectoryId === item.id
                      ? 'visible'
                      : 'hidden')),attrs:{"type":"arrow-up"},on:{"click":function($event){return _vm.updateFirstLevelSorts(groupIndex, 'up')}}}),_c('a-icon',{style:('visibility:' +
                    (groupIndex !== _vm.directoryList.length - 1 &&
                    _vm.mouseFocusOnDirectoryId === item.id
                      ? 'visible'
                      : 'hidden')),attrs:{"type":"arrow-down"},on:{"click":function($event){return _vm.updateFirstLevelSorts(groupIndex, 'down')}}}),_vm._v(" "+_vm._s(item.name)+" "),_c('a-icon',{staticClass:"menu-edit-level2",style:('visibility:' +
                    (_vm.mouseFocusOnDirectoryId === item.id ? 'visible' : 'hidden')),attrs:{"type":"edit"},on:{"click":function($event){$event.stopPropagation();return _vm.showEditDirectoryModal(item)}}})],1):_c('a-sub-menu',{key:item.id},[_c('div',{staticClass:"menu-title",attrs:{"slot":"title"},on:{"mouseenter":function($event){return _vm.mouseEnterDirectory(item.id)},"mouseleave":function($event){return _vm.mouseLeaveDirectory()}},slot:"title"},[_c('a-icon',{style:('visibility:' +
                      (groupIndex !== 0 && _vm.mouseFocusOnDirectoryId === item.id
                        ? 'visible'
                        : 'hidden')),attrs:{"type":"arrow-up"},on:{"click":function($event){return _vm.updateFirstLevelSorts(groupIndex, 'up')}}}),_c('a-icon',{style:('visibility:' +
                      (groupIndex !== _vm.directoryList.length - 1 &&
                      _vm.mouseFocusOnDirectoryId === item.id
                        ? 'visible'
                        : 'hidden')),attrs:{"type":"arrow-down"},on:{"click":function($event){return _vm.updateFirstLevelSorts(groupIndex, 'down')}}}),_c('span',{attrs:{"id":item.id}},[_vm._v(_vm._s(item.name))]),_c('a-icon',{staticClass:"menu-edit-level1",style:('visibility:' +
                      (_vm.mouseFocusOnDirectoryId === item.id
                        ? 'visible'
                        : 'hidden')),attrs:{"type":"edit"},on:{"click":function($event){$event.stopPropagation();return _vm.showEditDirectoryModal(item)}}})],1),_vm._l((item.children),function(directory,index){return _c('a-menu-item',{key:directory.id,attrs:{"id":directory.id},on:{"mouseenter":function($event){return _vm.mouseEnterDirectory(directory.id)},"mouseleave":function($event){return _vm.mouseLeaveDirectory()},"click":function($event){return _vm.openSecondaryDirectory(item.name, directory)}}},[_c('a-icon',{style:('visibility:' +
                      (index !== 0 && _vm.mouseFocusOnDirectoryId === directory.id
                        ? 'visible'
                        : 'hidden')),attrs:{"type":"arrow-up"},on:{"click":function($event){return _vm.updateSecondaryLevelSorts(groupIndex, index, 'up')}}}),_c('a-icon',{style:('visibility:' +
                      (index !== item.children.length - 1 &&
                      _vm.mouseFocusOnDirectoryId === directory.id
                        ? 'visible'
                        : 'hidden')),attrs:{"type":"arrow-down"},on:{"click":function($event){return _vm.updateSecondaryLevelSorts(groupIndex, index, 'down')}}}),_vm._v(" "+_vm._s(directory.name)+" "),_c('a-icon',{staticClass:"menu-edit-level2",style:('visibility:' +
                      (_vm.mouseFocusOnDirectoryId === directory.id
                        ? 'visible'
                        : 'hidden')),attrs:{"type":"edit"},on:{"click":function($event){$event.stopPropagation();return _vm.showEditDirectoryModal(directory)}}})],1)})],2)]})],2)],1):_vm._e(),_c('a-layout-content',[_c('router-view',{on:{"operateFromChild":_vm.operateFromChild}})],1)],1)],1),_c('addDirectoryModal',{attrs:{"show":_vm.showAddDirectoryModalFlag,"directoryList":_vm.directoryList,"moduleId":_vm.checkedModule.id,"directory":_vm.needEditDirectory},on:{"close":_vm.closeAddDirectoryModal}}),_c('inputTokenModal',{attrs:{"show":_vm.showInputTokenModalFlag},on:{"close":_vm.closeInputTokenModal}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }