import moment from 'moment'
import local from '../utils/local.js'

// 时间戳转时间
export function formatDate(value) {
  let date = new Date(value)
  date = moment(date).format('YYYY-MM-DD')
  return date
}

export function isMobileDevice() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  return /android|avantgo|blackberry|iemobile|ipad|iphone|ipod|opera mini|opera mobi|palm|pocket|psp|series(4|6)0|symbian|windows ce|windows phone|xda|xiino/i.test(
    userAgent
  )
}
